<template>
  <div class="operation-log-wrap">
    <el-form ref="form" size="small" class="search-pannel" label-position="left" :model="searchForm">
      <el-form-item label="日志类别：">
        <el-select placeholder="请选择" v-model="searchForm.activityType" @change="changeactType">
          <el-option v-for="item in activityTypeList" :key="item.actId" :label="item.actName" :value="item.actId" />
        </el-select>
      </el-form-item>
      <el-form-item label="编码：">
        <el-input v-model="searchForm.lockerCode" placeholder="请输入编码"></el-input>
      </el-form-item>
      <el-form-item label="操作人：">
        <el-input v-model="searchForm.operatorName" placeholder="请输入操作人姓名"></el-input>
      </el-form-item>
      <el-form-item label="操作时间：">
        <el-date-picker
          v-model="operationTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="monitorTime"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <el-row class="row-b">
      <el-button type="success" size="small" @click="onSearch">搜索</el-button>
      <el-button size="small" @click="onReset('form')">重置</el-button>
    </el-row>
    <Table
      :header-cell-style="{ background: '#F7F8FA ' }"
      :columns="columns"
      :data="tableData"
      :total="pageParams.total"
      :page="pageParams.pageNum"
      :limit="pageParams.pageSize"
      :hidden="!tableData.length"
      @pagination="updatePage"
    >
    </Table>
  </div>
</template>

<script>
import Table from '@/components/TablePage'
import { getActivityLog } from '@/api/modules/operation'
import { createUuidFn } from '@/utils'
const uuid = createUuidFn()
const activityTypeList = [
  { actId: 1, actName: '创建柜机' },
  { actId: 2, actName: '设备管理' },
  { actId: 3, actName: '可视化模板' },
  { actId: 4, actName: '登录柜机' },
  { actId: 5, actName: '柜机激活' },
  { actId: 6, actName: '操作柜机' },
  { actId: 7, actName: '远程控制' },
  { actId: 8, actName: '整机故障' },
  { actId: 9, actName: '联网日志' },
  { actId: 10, actName: '编辑柜机' },
  { actId: 11, actName: '删除柜机' },
  { actId: 12, actName: '状态变更' }
]
export default {
  name: 'operationLog',
  components: {
    Table
  },
  data() {
    return {
      searchForm: {
        activityType: '',
        lockerCode: '',
        operatorName: '',
        startTime: '',
        endTime: ''
      },
      operationTime: [],
      columns: [
        { label: '编码', prop: 'lockerCode' },
        { label: '日志类别', prop: 'activityTypeText' },
        { label: '操作内容', prop: 'description' },
        { label: '操作人', prop: 'operatorName' },
        { label: '操作时间', prop: 'operatorTm' }
      ],
      tableData: [],
      pageParams: {
        pageNum: 1,
        pageSize: 10,
        total: 1
      },
      activityTypeList: activityTypeList || []
    }
  },
  created() {
    this.onSearch()
  },
  methods: {
    // 获取table列表
    onSearch() {
      const data = {
        requestTime: new Date().getTime(),
        requestId: uuid('rid'),
        platformId: '001',
        data: {
          ...this.getParams()
        }
      }
      getActivityLog(data).then((res) => {
        const { records, pageNum, pageSize, total } = res.data
        this.tableData = records
        this.pageParams = { pageNum, pageSize, total }
        console.log('getActivityLog-res: ', res)
      })
    },
    onReset(formName) {
      this.$refs[formName].resetFields()
      this.searchForm = {
        activityType: '',
        lockerCode: '',
        operatorName: '',
        startTime: '',
        endTime: ''
      }
    },
    // 获取参数
    getParams() {
      const getParam = { ...this.searchForm }
      getParam.pageNum = this.pageParams.pageNum
      getParam.pageSize = this.pageParams.pageSize
      return getParam
    },
    // 更新分页
    updatePage(val) {
      if (val) {
        this.pageParams.pageNum = val.page
        this.pageParams.pageSize = val.limit
        this.onSearch()
      }
    },
    // 监听时间变化
    monitorTime(val) {
      const timedata = []
      val &&
        val.forEach((item) => {
          timedata.push(new Date(item).getTime())
        })
      const [start, end] = timedata
      this.searchForm.startTime = start
      this.searchForm.endTime = end
    },
    // 处理select
    changeactType(value) {
      this.searchForm.activityType = value
    }
  }
}
</script>

<style lang="scss" scoped>
.operation-log-wrap {
  padding: 24px;
}
.row-b {
  margin-bottom: 16px;
}
</style>
